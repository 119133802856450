import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ProfilePanel from '../components/ProfilePanel';
import { useTranslation } from 'react-i18next';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Profile(props) {

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar user={props.user} logout={props.logout} />
        <section className='py-8 bg-blueGray-50'>
          <div className='container px-4 mx-auto'>
            <div className='relative px-10 py-12 xl:py-16 xl:px-20 mb-8 sm:mb-9 bg-metricsgray overflow-hidden rounded-3xl'>
              <img
                className='absolute right-0 bottom-0'
                src='uinel-assets/images/dashboard-content/pic3.png'
                alt='Image of a 3D pie chart representing metrics'
              />
              <div className='relative z-10'>
                <h2 className='mb-5 text-5xl md:text-7xl text-darkBlueGray-400 font-heading font-semibold'>
                  {t('profile_title')}
                </h2>
                <p className='text-darkBlueGray-400 max-w-xs font-medium'>
                  {t('profile_subtitle')}
                </p>
              </div>
            </div>
            <ProfilePanel
              user={props.user}
              userData={props.userData}
              userSubscribed={props.userSubscribed}
            />
          </div>
        </section>
        <Footer />
      </>
    </React.Fragment>
  );
}

