// Realistic lighting: https://www.youtube.com/watch?v=7GGNzryHfTw
// TODO: Implement animation following:
// https://github.com/Hallway-Inc/AvatarWebKit/blob/main/examples/ready-player-me-tutorials/src/AvatarView.js
// For reading face pose: https://developers.google.com/mediapipe/solutions/vision/face_landmarker/web_js?authuser=2
// For animations with blender: https://dev.to/nourdinedev/how-to-use-threejs-and-react-to-render-a-3d-model-of-your-self-4kkf

import React from 'react';
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { storage } from '../Firebase';
import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import AudioRecorder from './AudioRecorder';
// From https://github.com/mrdoob/three.js/blob/master/examples/textures/equirectangular/venice_sunset_1k.hdr
import backgroundUrl from '../assets/uinel-assets/avatars/venice_sunset_1k.hdr';
import { consoleLogCustom, createNewConversation, getAvatarSizeInPixels, getLLMResponse } from './Utils';
import { AvailableLanguages } from './AvailableLanguages';
import { withTranslation } from 'react-i18next';

const SCALE = 7.5

class AvatarView extends React.Component {

  mainViewRef = React.createRef()
  chatHistory = []
  conversationId = null
  trajectories = {}
  referenceTime = 0
  speechAnimationRunning = false
  blinkAnimationRunning = false
  blinkValue = 0
  blinkAscending = true
  audioRecorder = new AudioRecorder(-45) // -45 is the default value for voiceMinDecibels
  lastAudioTimeRef = null
  maxAudioTimeRef = 29 // Maximum recording time in seconds (client.recognize should give error for audios longer than 60 seconds, but in fact the error appears on 30 seconds)
  settingsMenuOpen = false
  language = this.getDefaultLanguage()

  // If we want to use variables in JSX, they need to be inside state
  state = {
    avatarState: 'standby',
    audioHist: [0, 0, 0],
  }

  setAvatarState = (value) => {
    this.setState({
      avatarState: value,
      audioHist: this.state.audioHist,
    });
  }

  setAudioHist = (value) => {
    this.setState({
      avatarState: this.state.avatarState,
      audioHist: value,
    });
  }

  constructor(props, context) {

    super(props, context)

    // this.state = {
    //   showBubble: false,
    //   bubbleState: false,
    // }

    this.startQuery = this.startQuery.bind(this)
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.value !== props.value) {
      return {
        value: props.value,
        computed_prop: heavy_computation(props.value)
      }
    }
    return null
  }

  async getResponse(query = '') {
    consoleLogCustom('Updated version');
    const data = await getLLMResponse(
      query,
      this.props?.chatbotId,
      this.props?.parent,
      'avatar',
      this.props?.aid,
      this.conversationId,
      this.language,
      this.chatHistory,
    )
    consoleLogCustom(data);
    // Add new entry to chat_history
    this.chatHistory = [...this.chatHistory, { 'question': data['query'], 'answer': data['answer'] }];
    return data;
  }

  async stopRecording() {
    await this.audioRecorder.stopRecording();
    consoleLogCustom('Recording stopped');
    // Play recorded audio
    const audio2 = new Audio(this.audioRecorder.audio);
    audio2.load(); // To ensure audio.duration is not NaN
    audio2.addEventListener('loadedmetadata', async () => {
      consoleLogCustom('Audio duration: ', audio2.duration);
      // Play audio
      // await audio2.play();
      if (this.state.avatarState == 'recording') {
        this.setAvatarState('processing');
      }
    });
  }

  async startQuery() {

    // If conversationId is null, we need to start a new conversation
    if (this.conversationId == null) {
      consoleLogCustom('Starting new conversation');
      this.conversationId = await createNewConversation(
        this.props?.chatbotId,
      )
    }

    // Get microphone permission
    if (!this.audioRecorder.permission) {
      await this.audioRecorder.getMicrophonePermission();
    }

    // Start recording
    await this.audioRecorder.startRecording();
    consoleLogCustom('Recording started');

    if (this.state.avatarState != 'recording') {
      this.setAvatarState('recording');
      // Continue when avatarState setting has been updated
      while (this.state.avatarState != 'recording') {
        await new Promise(r => setTimeout(r, 50)); // 50 ms
        consoleLogCustom('Waiting for avatarState to be recording');
      }
    }

    // Wait until avatarState has updated
    while (this.state.avatarState == 'recording') {
      await new Promise(r => setTimeout(r, 50)); // 50 ms
      consoleLogCustom('Waiting for recording to stop');
    }

    // Get actual audio data as an octet stream (this.audioRecorder.audio is an URL)
    const audioDataResponse = await fetch(this.audioRecorder.audio);
    const audioData = await audioDataResponse.blob();

    // Upload audio to storage
    const audioRef = ref(storage, "speech_files/" + this.props?.chatbotId + "/" + this.conversationId + "/in/speech_in.mp3");
    const blob = new Blob([audioData], { type: 'audio/mp3' });
    const uploadResult = await uploadBytes(audioRef, blob); // TODO: Handle errors as in https://firebase.google.com/docs/storage/web/upload-files#full_example
    consoleLogCustom('Uploaded audio');

    // Get response from text using LLM model
    const response = await this.getResponse(''); // Query is empty because the server will use the audio file

    // Get download url for audio response from storage
    let url;
    try {
      const audioRef = ref(storage, "speech_files/" + this.props?.chatbotId + "/" + this.conversationId + "/out/speech_out.mp3");
      url = await getDownloadURL(audioRef);
    } catch (error) {
      switch (error.code) {
        case 'storage/object-not-found':
          consoleLogCustom("File doesn't exist")
          break;
        case 'storage/unauthorized':
          consoleLogCustom("User doesn't have permission to access the object")
          break;
        case 'storage/canceled':
          consoleLogCustom("User canceled the upload")
          break;
        case 'storage/unknown':
          consoleLogCustom("Unknown error occurred, inspect the server response")
          break;
        default:
          consoleLogCustom("Unknown error occurred, inspect the server response")
          break;
      }
    }

    // Play audio response
    const audio = new Audio(url);
    audio.load(); // To ensure audio.duration is not NaN
    audio.addEventListener('loadedmetadata', async () => {
      consoleLogCustom('Audio duration: ', audio.duration);
      // Generate blendshape trajectories from visemes and timestamps
      this.trajectories = this.generateBlendshapeTrajectories(response['visemes'], audio.duration);
      // Update avatarState
      if (this.state.avatarState == 'processing') {
        this.setAvatarState('speaking');
      }
      // Play audio
      await audio.play();
    });
    // while audio is playing, animate avatar
    audio.addEventListener('playing', () => {
      if (this.avatar != undefined) {
        consoleLogCustom('Playing audio');
        this.startAvatarAnimation(audio.duration);
      }
    });
    // Delete audio file from storage
    audio.addEventListener('ended', async () => {
      consoleLogCustom('Audio ended');
      const audioRef = ref(storage, "speech_files/" + this.props?.chatbotId + "/" + this.conversationId + "/out/speech_out.mp3");
      await deleteObject(audioRef);
      consoleLogCustom('Deleted audio out file');
    });
  }

  generateBlendshapeTrajectories(visemesInfo, duration) {
    // Generate blendshape trajectories from visemes and timestamps
    const nframes = Math.floor(duration * 60);
    const visemeEquivalences = {
      // https://docs.readyplayer.me/ready-player-me/api-reference/avatars/morph-targets/oculus-ovr-libsync
      // https://docs.aws.amazon.com/polly/latest/dg/ph-table-english-uk.html (see all languages)
      'viseme_sil': ['sil'],
      'viseme_PP': ['p', 'B', 'b', '0'],
      'viseme_FF': ['f'],
      'viseme_TH': ['T'],
      'viseme_DD': ['t', 'd', 'D'],
      'viseme_kk': ['k'],
      'viseme_CH': ['S', 'J'],
      'viseme_SS': ['s'],
      'viseme_nn': [],
      'viseme_RR': ['r'],
      'viseme_aa': ['@', 'a'],
      'viseme_E': ['e', 'E'],
      'viseme_I': ['i'],
      'viseme_O': ['o', 'O'],
      'viseme_U': ['u'],
    }
    const nvisemes = Object.keys(visemeEquivalences).length;

    // Extract timestamps and visemes
    const timestamps = visemesInfo.map((viseme) => viseme['time']);
    const visemes = visemesInfo.map((viseme) => viseme['value']);

    // Convert visemes using visemeEquivalences
    let convertedVisemes = [];
    let visemeKeys = Object.keys(visemeEquivalences);
    let visemeValues = Object.values(visemeEquivalences);

    visemes.forEach((viseme) => {
      visemeValues.forEach((visemeValue) => {
        if (visemeValue.includes(viseme)) {
          convertedVisemes.push(visemeKeys[visemeValues.indexOf(visemeValue)]);
        }
      });
    });

    // Initialize trajectories as a double array of size nvisemes x nframes
    let trajectories = {};
    for (let i = 0; i < nvisemes; i++) {
      trajectories[visemeKeys[i]] = new Array(nframes).fill(0);
    }

    // For each timestamp, generate the corresponding viseme trajectory
    const scaleFactor = 0.4;
    const slopeTime = 100; // ms
    timestamps.forEach((timestamp, index) => {
      // Get the value of the next timestamp. If there is no next timestamp, use the same value as the current timestamp
      let nextTimestamp = timestamps[index + 1] ? timestamps[index + 1] : timestamp;
      // Also, if the next index is the last one ('sil'), then use the same value as the current timestamp
      // This is because the last viseme is always 'sil', and the viseme before 'sil' does not need to be maintained
      let descentSlopeScaleFactor = 1; // Only used for smoothing the last descent slope
      if (index + 1 === timestamps.length - 1) {
        nextTimestamp = timestamp;
        descentSlopeScaleFactor = 2;
      }
      // Transform timestamps to frames
      const timeframe = Math.floor(timestamp * 60 / 1000);
      const nextTimeframe = Math.floor(nextTimestamp * 60 / 1000);
      // Calculate the number of frames for the linear slope
      let slopeFrames = Math.floor(slopeTime * 60 / 1000);
      // Limit slopeFrames in case the next timeframe is too close to the end of the audio
      if (nframes - nextTimeframe < slopeFrames) {
        slopeFrames = nframes - nextTimeframe;
      }
      // Limit slopeFrames in case the current timeframe is too close to the beginning of the audio
      if (timeframe < slopeFrames) {
        slopeFrames = timeframe;
      }
      // Set a linear slope starting slopeTime ms before the current timeframe for this viseme
      for (let i = timeframe - slopeFrames; i < timeframe; i++) {
        trajectories[convertedVisemes[index]][i] = scaleFactor * (i - timeframe + slopeFrames) / slopeFrames;
      }
      // Set to 1 the values of the trajectory between the current and next timeframe for this viseme
      for (let i = timeframe; i < nextTimeframe; i++) {
        trajectories[convertedVisemes[index]][i] = scaleFactor;
      }
      // Set a linear slope ending slopeTime ms after the next timeframe for this viseme
      for (let i = nextTimeframe; i < nextTimeframe + (slopeFrames * descentSlopeScaleFactor); i++) {
        trajectories[convertedVisemes[index]][i] = scaleFactor * (nextTimeframe + (slopeFrames * descentSlopeScaleFactor) - i) / (slopeFrames * descentSlopeScaleFactor);
      }
    });

    return trajectories;
  }

  loadBackground(url, renderer) {
    return new Promise((resolve) => {
      const loader = new RGBELoader()
      const generator = new THREE.PMREMGenerator(renderer)
      loader.load(url, (texture) => {
        const envMap = generator.fromEquirectangular(texture).texture
        generator.dispose()
        texture.dispose()
        resolve(envMap)
      })
    })
  }

  async componentDidMount() {
    const mainView = this.mainViewRef.current
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    this.renderer.setSize(this.props.size.width, this.props.size.height)
    this.renderer.outputColorSpace = THREE.SRGBColorSpace
    // this.renderer.toneMapping = THREE.ACESFilmicToneMapping
    this.renderer.toneMapping = THREE.ReinhardToneMapping;
    this.renderer.toneMappingExposure = 3;
    this.renderer.shadowMap.enabled = true;
    this.renderer.setClearColor(0x000000, 0); // the default
    mainView.appendChild(this.renderer.domElement)

    this.camera = new THREE.PerspectiveCamera(50, 1, 0.1, 2000)
    this.camera.position.set(0, 0.5, 4)
    // this.camera.rotation.set(-0.27, 0, 0)
    this.camera.lookAt(0, 0, -25)

    // this.controls = new OrbitControls(this.camera, this.renderer.domElement) // If control allowed, prevents lookat function from working

    this.scene = new THREE.Scene()
    // this.scene.environment = new THREE.Color(0xffffff)

    // Using lighting from the background instead of creating our own
    const background = await this.loadBackground(backgroundUrl, this.renderer)

    this.scene.environment = background
    this.scene.background = null // We do not need to render the background, only for lighting

    // // Make background transparent
    // this.scene.background = null

    // let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 2)
    // this.scene.add(hemiLight)

    // this.spotLight = new THREE.SpotLight(0xffffff, 3)
    // this.spotLight.castShadow = true;
    // this.spotLight.shadow.bias = -0.0001;
    // this.spotLight.shadow.mapSize.width = 1024 * 4;
    // this.spotLight.shadow.mapSize.height = 1024 * 4;
    // this.scene.add(this.spotLight);


    // this.scene.add(new THREE.AxesHelper(500)) // BORRAR

    await this.loadModel()

    // Log the morphTargetDictionary of each child, indicating the name of the child
    // this.avatar.children.forEach((child) => consoleLogCustom(child.name, child.morphTargetDictionary))

    // Create render loop
    this.renderer.setAnimationLoop(this.renderScene.bind(this))
  }

  async startAvatarAnimation(duration) {
    // For the duration of the audio, animate the avatar by calling animateSpeechAnimationFrame

    // Get the current time
    this.referenceTime = performance.now(); // in ms

    // Set the speechAnimationRunning flag to true
    this.speechAnimationRunning = true;

    // After the duration of the audio, set the speechAnimationRunning flag to false
    setTimeout(() => {
      this.speechAnimationRunning = false;
      // Update avatarState
      if (this.state.avatarState == 'speaking') {
        this.setAvatarState('standby');
      }
    }, duration * 1000);

    // // For each viseme, animate the avatar
    // visemes.forEach((viseme) => {
    //   setTimeout(() => {
    //     this.animateAvatar(viseme);
    //   }
    //     , viseme['time'] * 1000);
    // }
    // );

  }

  animateSpeechAnimationFrame() {

    // const hips = this.avatar.children.find((child) => child.name === 'Hips');
    // const eyeLeft = this.avatar.children.find((child) => child.name === 'EyeLeft');
    // const eyeRight = this.avatar.children.find((child) => child.name === 'EyeRight');
    // const head = this.avatar.children.find((child) => child.name === 'Wolf3D_Head');
    // const teeth = this.avatar.children.find((child) => child.name === 'Wolf3D_Teeth');
    // const body = this.avatar.children.find((child) => child.name === 'Wolf3D_Body');
    // const outfilBottom = this.avatar.children.find((child) => child.name === 'Wolf3D_Outfit_Bottom');
    // const outfitFootwear = this.avatar.children.find((child) => child.name === 'Wolf3D_Outfit_Footwear');
    // const outfitTop = this.avatar.children.find((child) => child.name === 'Wolf3D_Outfit_Top');
    // const hair = this.avatar.children.find((child) => child.name === 'Wolf3D_Hair');

    const head = this.avatar.children.find((child) => child.name === 'Wolf3D_Avatar');

    // Print morph targets
    if (Math.floor(this.renderer.info.render.frame % 60) === 0) {
      // consoleLogCustom('head.morphTargetDictionary: ', head.morphTargetDictionary);
    }

    // Get the current frame index
    let frameIndex = Math.floor((performance.now() - this.referenceTime) * 60 / 1000); // 60 fps

    // Limit frame index to the length of the trajectories
    const nframes = Object.values(this.trajectories)[0].length;
    if (frameIndex >= nframes) {
      frameIndex = nframes - 1;
    }

    // Update visemes using trajectories
    const arKitKeys = Object.keys(this.trajectories);
    for (let i = 0; i < arKitKeys.length; i++) {
      const arKitKey = arKitKeys[i];
      const index = head.morphTargetDictionary[arKitKey];
      head.morphTargetInfluences[index] = this.trajectories[arKitKey][frameIndex];
    }

    let { pitch, roll, yaw } = this.avatar.rotation
    let { x, y, z } = this.avatar.position

    roll -= 0.001
    y += 0.001

    // this.avatar.rotation.set(pitch, roll, yaw);
    // this.avatar.position.set(x, y, z);

    // Every second
    if (Math.floor(this.renderer.info.render.frame % 60) === 0) {
      // consoleLogCustom('head.morphTargetInfluences[index]: ', head.morphTargetInfluences[index]);
    }
  }

  animateBlinkAnimationFrame() {
    const eyeLeft = this.avatar.children.find((child) => child.name === 'EyeLeft');
    const eyeRight = this.avatar.children.find((child) => child.name === 'EyeRight');
    const head = this.avatar.children.find((child) => child.name === 'Wolf3D_Avatar');

    const blinkFrames = 6;

    const arKitKeys = ['eyeBlinkLeft', 'eyeBlinkRight'];

    if (this.blinkValue === 0) {
      this.blinkAscending = true;
    } else if (this.blinkValue === blinkFrames) {
      this.blinkAscending = false;
    }

    if (this.blinkAscending) {
      this.blinkValue += 1;
    } else {
      this.blinkValue -= 1;
    }

    for (let i = 0; i < arKitKeys.length; i++) {
      const arKitKey = arKitKeys[i];
      const index = head.morphTargetDictionary[arKitKey];
      head.morphTargetInfluences[index] = this.blinkValue * (1 / blinkFrames);
    }

    if ((this.blinkValue === 0) && (this.blinkAscending === false)) {
      this.blinkAnimationRunning = false;
    }
  }

  async componentDidUpdate(oldProps) {

    if ((this.props?.avatarUrl && (this.props?.avatarUrl !== oldProps?.avatarUrl))
      || (this.props?.size.width && (this.props?.size.width !== oldProps?.size.width))
      || (this.props?.size.height && (this.props?.size.height !== oldProps?.size.height))) {
      await this.loadModel()
    }

    // this.renderer.domElement.style.cssText = `display: ${this.props.showIFrame ? 'block' : 'none'}`
  }

  loadBackground(url, renderer) {
    return new Promise((resolve) => {
      const loader = new RGBELoader()
      const generator = new THREE.PMREMGenerator(renderer)
      loader.load(url, (texture) => {
        const envMap = generator.fromEquirectangular(texture).texture
        generator.dispose()
        texture.dispose()
        resolve(envMap)
      })
    })
  }

  async loadModel() {
    // Set the avatar state to processing so that the loading spinner is shown while the avatar is loading
    this.setAvatarState('processing')
    // If there are any other avatars in the scene, remove them
    if (this.avatar != undefined) {
      this.scene.remove(this.avatar);
    }
    // Change the window size
    if (this.props.size) {
      this.renderer.setSize(this.props.size.width, this.props.size.height)
      this.camera.aspect = this.props.size.width / this.props.size.height
      this.camera.updateProjectionMatrix()
    }
    // Load avatar
    const gltf = await this.loadGLTF(this.props.avatarUrl)
    this.avatar = gltf.scene.children[0]
    this.avatar.position.set(0, -4, 0)
    this.avatar.scale.setScalar(SCALE)
    this.avatar.traverse(n => {
      if (n.isMesh) {
        n.castShadow = true
        n.receiveShadow = true
        if (n.material.map) n.material.map.anisotropy = 16
      }
    })
    this.scene.add(this.avatar)
    // Return the avatar state to standby
    this.setAvatarState('standby')
  }

  renderScene() {
    // Get audio frequency data
    if (this.state.avatarState == 'recording') {
      try {
        this.audioRecorder.analyser.getByteFrequencyData(this.audioRecorder.dataArray)
        // Update audio histogram
        let audioHist = [0, 0, 0];
        // Get an audio energy estimate (https://stackoverflow.com/a/74690536)
        let sum = 0
        for (const amplitude of this.audioRecorder.dataArray) {
          sum += amplitude * amplitude
        }
        // If the audio energy is 0, the decibel level is below the minimum threshold
        if (sum === 0) {
          audioHist[1] = 2;
        } else {
          this.lastAudioTimeRef = this.audioRecorder.audioContext.currentTime;
          const volume = Math.sqrt(sum / this.audioRecorder.dataArray.length)
          const adjustedVolume = volume / 255 * 25
          // Limit between 0 and 25
          if (adjustedVolume < 0) {
            audioHist[1] = 0;
          } else if (adjustedVolume > 25) {
            audioHist[1] = 25;
          } else {
            audioHist[1] = adjustedVolume;
          }
        }
        audioHist[0] = audioHist[1] / 2;
        audioHist[2] = audioHist[1] / 1.5;
        this.setAudioHist(audioHist);
        //TODO: Use an offline speech recognition library to detect no speech instead (only for this purpose, not for the actual speech recognition)
        //TODO: Or alternatively, use a stream of audio data instead of a file
        if ((this.audioRecorder.audioContext.currentTime - this.lastAudioTimeRef > 3) || // If there has been no audio for 3 seconds
          (this.audioRecorder.audioContext.currentTime > this.maxAudioTimeRef)) { // Or if the maximum recording time has been reached
          this.stopRecording();
        }
      } catch (error) {
        consoleLogCustom(error);
      }
    }
    // // Update spotLight position
    // this.spotLight.position.set(
    //   this.camera.position.x + 10,
    //   this.camera.position.y + 10,
    //   this.camera.position.z + 10)
    // Animate speech
    if (this.speechAnimationRunning) {
      this.animateSpeechAnimationFrame();
    }
    // Randomly command blinks
    if (!this.blinkAnimationRunning && Math.random() < 0.004) {
      this.blinkAnimationRunning = true;
    }
    // Animate blinks
    if (this.blinkAnimationRunning) {
      this.animateBlinkAnimationFrame();
    }
    this.renderer.render(this.scene, this.camera)
  }

  loadGLTF(url) {
    return new Promise((resolve) => {
      const loader = new GLTFLoader()
      loader.load(url, (gltf) => resolve(gltf))
    })
  }

  renderPrimaryButtonIcon = () => {
    switch (this.state.avatarState) {
      case 'recording':
        return (
          // Paint 3 vertical bars, in the form of a histogram
          <svg className="rotate-180 text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <rect x="4" y={12 - this.state.audioHist[0] / 2} width="3" height={this.state.audioHist[0]} rx="1" fill="currentColor" />
            <rect x="10" y={12 - this.state.audioHist[1] / 2} width="3" height={this.state.audioHist[1]} rx="1" fill="currentColor" />
            <rect x="16" y={12 - this.state.audioHist[2] / 2} width="3" height={this.state.audioHist[2]} rx="1" fill="currentColor" />
          </svg>
        )
      case 'processing':
        return (
          // Paint a semicircular indicator using circle and stroke-dasharray
          <svg className="text-white w-10 h-10 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeDasharray="30 30" strokeDashoffset="0" strokeLinecap="round" />
          </svg>
        )
      case 'speaking':
        return (
          // Paint a speaker icon
          <svg className="text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" >
            <path d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 001.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06zM18.584 5.106a.75.75 0 011.06 0c3.808 3.807 3.808 9.98 0 13.788a.75.75 0 11-1.06-1.06 8.25 8.25 0 000-11.668.75.75 0 010-1.06z" />
            <path d="M15.932 7.757a.75.75 0 011.061 0 6 6 0 010 8.486.75.75 0 01-1.06-1.061 4.5 4.5 0 000-6.364.75.75 0 010-1.06z" />
          </svg>
        )
      case 'standby':
        return (
          // Paint a microphone icon
          <svg className="text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
          </svg>
        )
      default:
        return (
          <svg className="text-white w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
          </svg>
        )
    }
  }

  renderSecondaryButtonIcon = () => {
    return (
      <svg className="w-10 h-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
    )
  }

  handleOnClickPrimaryButton = async () => {
    switch (this.state.avatarState) {
      case 'recording':
        await this.stopRecording();
        break;
      case 'processing':
        break;
      case 'speaking':
        break;
      case 'standby':
        await this.startQuery();
        break;
      default:
        break;
    }
  }

  handleOnClickSecondaryButton = () => {
    this.settingsMenuOpen = !this.settingsMenuOpen;
    // Get settingsMenu element
    const settingsMenu = document.getElementsByName('settingsMenu')[0];
    // If settingsMenu is open, remove hidden class
    if (this.settingsMenuOpen) {
      settingsMenu.classList.remove('hidden');
    } else {
      settingsMenu.classList.add('hidden');
    }

    consoleLogCustom('this.settingsMenuOpen: ', this.settingsMenuOpen);
  }

  handleOnChangeLanguage = (event) => {
    this.language = event.target.value;
    const languageSelector = document.getElementById('language');
    languageSelector.value = this.language;
    const savedMessage = document.getElementsByName('saved-message')[0];
    savedMessage.classList.remove('hidden');
    setTimeout(() => {
      savedMessage.classList.add('hidden');
    }, 2000);
    consoleLogCustom('this.language: ', this.language);
    this.forceUpdate(); // Force update to render the new language. TODO: Find a better way to do this
  }

  getDefaultLanguage() {
    // Get language from props
    const defaultLanguage = this.props?.language ?? this.props.i18n.language;
    // Check if language is available
    if (AvailableLanguages.filter((availableLanguage) => availableLanguage.value === defaultLanguage).length === 0) {
      consoleLogCustom('Language not available. Using selected global language instead.');
      return this.props.i18n.language;
    } else {
      return defaultLanguage;
    }
  }

  getButtonPositionClasses = (button) => {
    if (button === 'primary') {
      // Primary button is always on the bottom left corner
      if (this.props?.aid && this.props?.aid.startsWith('male')) {
        if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('small').width) {
          return 'absolute left-16 bottom-2';
        } else if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('medium').width) {
          return 'absolute left-20 bottom-4';
        } else if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('large').width) {
          return 'absolute left-32 bottom-8';
        }
      } else {
        if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('small').width) {
          return 'absolute left-20 bottom-4';
        } else if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('medium').width) {
          return 'absolute left-24 bottom-8';
        } else if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('large').width) {
          return 'absolute left-36 bottom-14';
        }
      }
    } else if (button === 'secondary') {
      // Secondary button is always on the bottom right corner
      if (this.props?.aid && this.props?.aid.startsWith('male')) {
        if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('small').width) {
          return 'absolute right-16 bottom-2';
        } else if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('medium').width) {
          return 'absolute right-20 bottom-4';
        } else if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('large').width) {
          return 'absolute right-32 bottom-8';
        }
      } else {
        if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('small').width) {
          return 'absolute right-20 bottom-4';
        } else if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('medium').width) {
          return 'absolute right-24 bottom-8';
        } else if (this.props?.size && this.props?.size.width === getAvatarSizeInPixels('large').width) {
          return 'absolute right-36 bottom-14';
        }
      }
    }
  }

  render = () => (
    <div
      className='relative'>
      <div
        ref={this.mainViewRef}
        className="avatarView bg-transparent"
        style={{
          display: `${!this.props.showIFrame ? 'block' : 'none'}`,
          // height: calc(100vh - 100px);
        }}
      />
      <div className={this.getButtonPositionClasses('primary')}>
        {/* <span class={(this.state.avatarState === 'standby' ? '' : 'hidden ') + "animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-600 opacity-75 z-40"}></span> */}
        <button
          onClick={this.handleOnClickPrimaryButton}
          className='flex flex-col items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-700 w-14 h-14'>
          {this.renderPrimaryButtonIcon()}
        </button>
      </div>
      <div className={this.getButtonPositionClasses('secondary')}>
        {/* <span class={(this.state.avatarState === 'standby' ? '' : 'hidden ') + "animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-600 opacity-75 z-40"}></span> */}
        <button
          onClick={this.handleOnClickSecondaryButton}
          className='flex flex-col items-center justify-center rounded-full bg-gray-400 hover:bg-gray-500 w-14 h-14'>
          {this.renderSecondaryButtonIcon()}
        </button>
      </div>
      {/* Render settings menu screen on top of all previous elements */}
      <div
        name="settingsMenu"
        className={'hidden absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 z-[600] flex flex-col items-center justify-center'} >
        {/* Render button to close menu on the top right corner */}
        <button
          onClick={this.handleOnClickSecondaryButton} // Close menu
          className='absolute top-4 right-4 flex flex-col items-center justify-center rounded-full bg-transparent w-6 h-6'>
          <svg className="w-6 h-6 text-gray-400 hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {/* Render settings menu content */}
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row items-center justify-center">
            <h2 className="text-xl font-bold text-gray-900">{this.props.t('chatbot_language', { lng: this.language.split('-')[0] })}</h2>
            <select
              name="language"
              id="language"
              className="ml-4 w-40 h-10 rounded-md"
              defaultValue={this.getDefaultLanguage()}
              onChange={this.handleOnChangeLanguage}>
              {AvailableLanguages.map((language) => {
                return (
                  <option value={language['value']} key={language['value']}>{this.props.t('languages_' + language['value'].replace('-', '_'), { lng: this.language.split('-')[0], rgn: '(US)' })}</option>
                )
              })}
            </select>
          </div>
          <span
            className="hidden text-sm text-gray-500"
            name="saved-message">{this.props.t('chatbot_setting_saved', { lng: this.language.split('-')[0] })}</span>
        </div>
        <div className='text-sm absolute bottom-2 right-2'>
          Powered by
          {/* Open link in new tab */}
          <a
            className='ml-1'
            href="https://chattier.dev"
            target='_blank'
            rel="noreferrer" >
            <b>Chattier</b>
          </a>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(AvatarView); // https://react.i18next.com/latest/withtranslation-hoc
