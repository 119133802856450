import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function SubscriptionSuccess(props) {

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar user={props.user} logout={props.logout} />
        <section className='pt-24 pb-32 bg-gradient-gray2 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='max-w-md mx-auto mb-20'>
              <h2 className='mb-4 font-heading font-semibold text-center text-6xl sm:text-7xl text-gray-900'>
                {t('subscription_success_title')}
              </h2>
              <p className='text-lg text-gray-600 text-center'>
                {t('subscription_success_subtitle')}
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </>
    </React.Fragment>
  );
}

