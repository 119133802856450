import femaleAvatar1 from '../assets/uinel-assets/avatars/female1.glb';
import femaleAvatar2 from '../assets/uinel-assets/avatars/female2.glb';
import femaleAvatar3 from '../assets/uinel-assets/avatars/female3.glb';
import maleAvatar1 from '../assets/uinel-assets/avatars/male1.glb';
import maleAvatar2 from '../assets/uinel-assets/avatars/male2.glb';
import maleAvatar3 from '../assets/uinel-assets/avatars/male3.glb';
import { auth } from '../Firebase';
import { getDomainFromUrl } from './Domain';

export function getChatSize(size) {
  if (size === "small") {
    return "w-[300px] h-[450px]";
  } else if (size === "medium") {
    return "w-[350px] h-[525px]";
  } else if (size === "large") {
    return "w-[400px] h-[600px]";
  } else {
    return "w-[400px] h-[600px]";
  }
}

export function getChatSizeInPixels(size) {
  if (size === "small") {
    return { width: 300, height: 450 };
  } else if (size === "medium") {
    return { width: 350, height: 525 };
  } else if (size === "large") {
    return { width: 400, height: 600 };
  } else {
    return { width: 350, height: 525 };
  }
}

export function getAvatarSize(size) {
  if (size === "small") {
    return "w-[300px] h-[300px]";
  } else if (size === "medium") {
    return "w-[400px] h-[400px]";
  } else if (size === "large") {
    return "w-[500px] h-[500px]";
  } else {
    return "w-[400px] h-[400px]";
  }
}

export function getAvatarSizeInPixels(size) {
  if (size === "small") {
    return { width: 300, height: 300 };
  } else if (size === "medium") {
    return { width: 400, height: 400 };
  } else if (size === "large") {
    return { width: 500, height: 500 };
  } else {
    return { width: 400, height: 400 };
  }
}

export function getChatOrAvatarSize(type, size) {
  if (type === "avatar") {
    return getAvatarSize(size);
  } else if (type === "chat") {
    return getChatSize(size);
  }
}

export function getChatOrAvatarSizeInPixels(type, size) {
  if (type === "avatar") {
    return getAvatarSizeInPixels(size);
  } else if (type === "chat") {
    return getChatSizeInPixels(size);
  }
}

export function getAvatarUrl(aid) {
  switch (aid) {
    case 'female1':
      return femaleAvatar1;
    case 'female2':
      return femaleAvatar2;
    case 'female3':
      return femaleAvatar3;
    case 'male1':
      return maleAvatar1;
    case 'male2':
      return maleAvatar2;
    case 'male3':
      return maleAvatar3;
    default:
      return femaleAvatar1;
  }
}

export async function createNewConversation(cid) {
  const response = await fetch(getBackendUrl() + "/create-conversation", {
    method: "POST",
    headers: {
      'cid': cid,
    },
  });
  const data = await response.json();
  return data.conversationid;
}

export async function getLLMResponse(query, cid, parent, btype, aid, conversationId, locale, chatHistory, shopify=null) {
  // Get response from LLM
  const response = await fetch(getBackendUrl() + "/get-response", {
    method: "POST",
    headers: {
      'query': query,
      'cid': cid,
      'parent': parent,
      'btype': btype,
      'aid': aid,
      'conversationid': conversationId,
      'locale': locale,
    },
    body: JSON.stringify({'chathistory': chatHistory, 'shopify': shopify}),
  });
  const data = await response.json();
  return data;
}

export function getMaxNumberOfChatbots(price_id) {
  switch (price_id) {
    case 'price_1Nvl1lHtJLu7cd9l6vMRRWQq':
      return null; // Pay as you go (billed monthly)
    default:
      return null; // Free Plan
  }
}

export function stringifyDate(miliseconds, locale) {
  // Options for date formatting
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(miliseconds).toLocaleString(locale, options);
}

export function stringifyToday(locale) {
  // Options for date formatting
  // Example: "Saturday, 30 November 2019"
  const options = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  const today = new Date().toLocaleString(locale, options);
  // Capitalize first letter
  return today.charAt(0).toUpperCase() + today.slice(1);
}

export async function getSubscriptionStatus(uid) {
  const token = await auth.currentUser.getIdToken();
  const response = await fetch(getBackendUrl() + "/get-subscription-status", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      'uid': uid,
    },
  });
  const data = await response.json();
  consoleLogCustom(data);
  return data['status'];
}

export function checkSubscriptionStatusValidity(status) {
  // https://stripe.com/docs/billing/subscriptions/overview#subscription-statuses
  var result = false;
  if (status === "trialing") {
    result = false; // Trials are not implemented for this app
  } else if (status === "active") {
    result = true;
  } else if (status === "incomplete") {
    result = false;
  } else if (status === "incomplete_expired") {
    result = false;
  } else if (status === "past_due") {
    result = true; // Allow users to continue using the app while retrying payment
  } else if (status === "canceled") {
    result = false;
  } else if (status === "unpaid") {
    result = false;
  } else if (status === "paused") {
    result = false; // Only after trial ends
  }
  return result
}

export async function getCustomerPortalUrl(uid, returnUrl) {
  const token = await auth.currentUser.getIdToken();
  const response = await fetch(getBackendUrl() + "/create-customer-portal", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      'uid': uid,
      'return_url': returnUrl,
    },
  });
  const data = await response.json();
  consoleLogCustom(data);
  return data['url'];
}

export function getBackendUrl() {
  var url;
  // If the frontend is in a preview environment, use the latest backend for testing
  consoleLogCustom('Backend URL: ' + window.location.hostname);
  if (window.location.hostname.includes("preview")) {
    url = `${process.env.REACT_APP_API_URL_LATEST}`;
  } else {
    url = `${process.env.REACT_APP_API_URL}`;
  }
  return url;
}

export function consoleLogCustom() {
  // Only print to console if current domain is not chattier.dev
  if (!(getDomainFromUrl(window.location.hostname) == "chattier.dev")) {
    var args = Array.prototype.slice.call(arguments);
    console.log.apply(console, args);
  }
}

export async function countTokens(uid, text) {
  // Count tokens in backend using tiktoken Python library
  const token = await auth.currentUser.getIdToken();
  const response = await fetch(getBackendUrl() + "/count-tokens", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      'uid': uid,
    },
    body: JSON.stringify({
      text: text,
    }),
  });
  const data = await response.json();
  return data['tokens'];
}

export function renderPricesTable(t, includePrices=true) {
  return (
    <table className='table-auto'>
      <thead>
        <tr>
          <th className='px-1 py-1'>{t('about_pricing_table_header_1')}</th>
          <th className='px-1 py-1'>{t('about_pricing_table_header_2')}</th>
          <th className='px-1 py-1'>{t('about_pricing_table_header_3')}</th>
          {includePrices &&
            <th className='px-1 py-1'>{t('about_pricing_table_header_4')}</th>
          }
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='border px-1 py-1'>{'Chat'}</td>
          <td className='border px-1 py-1'>{'GPT-4o mini'}</td>
          <td className='border px-1 py-1'>{MessageCredits['chat']['gpt-4o-mini-2024-07-18']}</td>
          {includePrices &&
            <td className='border px-1 py-1'>{'$' + (0.01 * MessageCredits['chat']['gpt-4o-mini-2024-07-18']).toFixed(2)}</td>
          }
        </tr>
        <tr>
          <td className='border px-1 py-1'>{'Chat'}</td>
          <td className='border px-1 py-1'>{'GPT-4o'}</td>
          <td className='border px-1 py-1'>{MessageCredits['chat']['gpt-4o-2024-08-06']}</td>
          {includePrices &&
            <td className='border px-1 py-1'>{'$' + (0.01 * MessageCredits['chat']['gpt-4o-2024-08-06']).toFixed(2)}</td>
          }
        </tr>
        <tr>
          <td className='border px-1 py-1'>{'Avatar'}</td>
          <td className='border px-1 py-1'>{'GPT-4o mini'}</td>
          <td className='border px-1 py-1'>{MessageCredits['avatar']['gpt-4o-mini-2024-07-18']}</td>
          {includePrices &&
            <td className='border px-1 py-1'>{'$' + (0.01 * MessageCredits['avatar']['gpt-4o-mini-2024-07-18']).toFixed(2)}</td>
          }
        </tr>
        <tr>
          <td className='border px-1 py-1'>{'Avatar'}</td>
          <td className='border px-1 py-1'>{'GPT-4o'}</td>
          <td className='border px-1 py-1'>{MessageCredits['avatar']['gpt-4o-2024-08-06']}</td>
          {includePrices &&
            <td className='border px-1 py-1'>{'$' + (0.01 * MessageCredits['avatar']['gpt-4o-2024-08-06']).toFixed(2)}</td>
          }
        </tr>
      </tbody>
    </table>
  );
}

export const MessageCredits = {
  'chat': {
    'gpt-4o-mini-2024-07-18': 1,
    'gpt-4o-2024-08-06': 2,
  },
  'avatar': {
    'gpt-4o-mini-2024-07-18': 6,
    'gpt-4o-2024-08-06': 7,
  },
}