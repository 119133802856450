import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: navigator.language,
  resources: {
    ar: {
      translations: require('./locales/ar/translations.json')
    },
    da: {
      translations: require('./locales/da/translations.json')
    },
    de: {
      translations: require('./locales/de/translations.json')
    },
    en: {
      translations: require('./locales/en/translations.json')
    },
    es: {
      translations: require('./locales/es/translations.json')
    },
    fr: {
      translations: require('./locales/fr/translations.json')
    },
    is: {
      translations: require('./locales/is/translations.json')
    },
    it: {
      translations: require('./locales/it/translations.json')
    },
    ja: {
      translations: require('./locales/ja/translations.json')
    },
    nl: {
      translations: require('./locales/nl/translations.json')
    },
    pl: {
      translations: require('./locales/pl/translations.json')
    },
    pt: {
      translations: require('./locales/pt/translations.json')
    },
    ru: {
      translations: require('./locales/ru/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es'];

export default i18n;