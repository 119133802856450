import { consoleLogCustom } from "./Utils";

const mimeType = "audio/webm";

class AudioRecorder {

  permission = false;
  mediaRecorder = null;
  recordingStatus = "inactive";
  stream = null;
  audioChunks = [];
  audio = null;
  analyser = null;
  voiceMinDecibels = null;
  audioContext = null;
  // streamSource = null;

  constructor(voiceMinDecibels) {
    this.voiceMinDecibels = voiceMinDecibels;
  }

  getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        this.permission = true;
        this.stream = streamData;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  startRecording = async () => {
    this.recordingStatus = "recording";
    //create new Media recorder instance using the stream
    this.mediaRecorder = new MediaRecorder(this.stream, { type: mimeType });
    // //set the MediaRecorder instance to the mediaRecorder ref
    // this.mediaRecorder.current = media;
    //invokes the start method to start the recording process
    this.mediaRecorder.start();
    let localAudioChunks = [];
    this.audioChunks = localAudioChunks;
    this.audioContext = new AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    const streamSource = this.audioContext.createMediaStreamSource(this.stream);
    streamSource.connect(this.analyser);
    // this.analyser.connect(this.audioContext.destination);
    this.analyser.fftSize = 32;//2048;
    this.analyser.minDecibels = this.voiceMinDecibels;
    const bufferLength = this.analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(bufferLength);
    this.mediaRecorder.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
      this.analyser.getByteFrequencyData(this.dataArray);
      consoleLogCustom(this.dataArray);
    };
  };

  stopRecording = async () => {
    this.recordingStatus = "inactive";
    // Stops the recording instance
    // this.mediaRecorder.stop(); // Only stops the recording, but does not stop the stream
    this.stream.getTracks().forEach((track) => {
      if (track.readyState == 'live' && track.kind === 'audio') {
        track.stop();
      }
    });
    this.permission = false;
    // Return when the recording has stopped
    return new Promise((resolve) => {
      this.mediaRecorder.onstop = () => {
        consoleLogCustom("data available after MediaRecorder.stop() called.");
        //creates a blob file from the audiochunks data
        const audioBlob = new Blob(this.audioChunks, { type: mimeType });
        //creates a playable URL from the blob file.
        const audioUrl = URL.createObjectURL(audioBlob);
        this.audio = audioUrl;
        this.audioChunks = [];
        resolve();
      };
      consoleLogCustom("stopRecording() called");
    }
    );
  };

};

export default AudioRecorder;