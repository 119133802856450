import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../Firebase';
import WarningIcon from '../components/WarningIcon';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { getDomainFromUrl } from '../components/Domain';
import { useTranslation } from 'react-i18next';
import { consoleLogCustom } from '../components/Utils';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function SignIn(props) {

  const [emailWarning, setEmailWarning] = React.useState('');
  const [loginEmail, setLoginEmail] = React.useState("");
  const [signingIn, setSigningIn] = React.useState(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    let ignore = false;
    if (!ignore) {
      // When loading signin page, reset login email and password
      setLoginEmail('');
    }
    return () => { ignore = true; }
  }, []);

  const navigate = useNavigate();

  async function handleClick() {
    if (!loginEmail) {
      setEmailWarning(t('signin_email_warning_1'));
    } else {
      setEmailWarning('');
      setSigningIn(true);
      const error = await login();
      if (error == 'ok') {
        navigate('/');
        alert(t('signin_alert'));
      } else if (error == 'auth/invalid-email') {
        setEmailWarning(t('signin_email_warning_2'));
      } else if (error == 'auth/user-disabled') {
        setEmailWarning(t('signin_email_warning_3'));
      } else if (error == 'auth/user-not-found') {
        setEmailWarning(t('signin_email_warning_4'));
      }
    }
  }

  async function login() {
    consoleLogCustom('Logging in...');
    try {
      // Get current URL
      const url = window.location.href;
      consoleLogCustom('url: ', url);
      // Get domain from URL
      const domain = getDomainFromUrl(url);
      // If the domain is valid
      if (!((domain == "chattier.dev") || (domain.startsWith("chattier-ea7e5--chattier-preview-")))) {
        consoleLogCustom('Invalid domain: ', domain);
        return 'Invalid domain';
      }
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'https://' + domain + '/process-signin?cartId=1234',
        // This must be true.
        handleCodeInApp: true,
      };
      consoleLogCustom('Sending sign in email to: ', loginEmail);
      consoleLogCustom('actionCodeSettings: ', actionCodeSettings);
      await sendSignInLinkToEmail(auth, loginEmail, actionCodeSettings);
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', loginEmail);
      // consoleLogCustom(userCredential.user);
      return 'ok';
    } catch (e) {
      consoleLogCustom(e.code);
      consoleLogCustom(e.message);
      return e.code;
    };
  };

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Navbar user={props.user} logout={props.logout} />
      <section className='pb-24'>
        <div className='pt-24 pb-64 xl:pt-52 lg:pb-80 bg-blueGray-100 text-center rounded-b-10xl'>
          <div className='container px-4 mx-auto'>
            <h2 className='mb-12 text-9xl md:text-10xl xl:text-11xl leading-tight font-heading font-medium'>
              {t('signin_title')}
            </h2>
            <p className='text-xl text-gray-400'>
            {t('signin_subtitle')}
            </p>
          </div>
        </div>
        <div className='container px-4 mx-auto'>
          <div className='-mt-52 max-w-4xl mx-auto py-10 px-8 bg-white rounded-4xl shadow-lg'>
            <div className='flex flex-wrap justify-center -mb-4 -mx-4'>
              <div className='hidden w-full md:w-auto px-4 mb-4'>
                <a
                  className='flex items-center justify-center py-5 px-5 border-2 border-gray-50 hover:border-gray-100 rounded-xl'
                  href='#'
                >
                  <img
                    className='w-6 h-6 mr-4'
                    src='uinel-assets/images/sign-up/google.svg'
                    alt='Google logo'
                  />
                  <p className='font-bold uppercase'>Sign in with Google</p>
                </a>
              </div>
              <div className='hidden w-full md:w-auto px-4 mb-4'>
                <a
                  className='flex items-center justify-center py-5 px-5 border-2 border-gray-50 hover:border-gray-100 rounded-xl'
                  href='#'
                >
                  <img
                    className='w-6 h-6 mr-4'
                    src='uinel-assets/images/sign-up/apple.svg'
                    alt='Apple logo'
                  />
                  <p className='font-bold uppercase'>Apple</p>
                </a>
              </div>
              <div className='hidden w-full md:w-auto px-4 mb-4'>
                <a
                  className='flex items-center justify-center py-5 px-5 border-2 border-gray-50 hover:border-gray-100 rounded-xl'
                  href='#'
                >
                  <img
                    className='w-6 h-6 mr-4'
                    src='uinel-assets/images/sign-up/facebook.svg'
                    alt='Facebook logo'
                  />
                  <p className='font-bold uppercase'>Facebook</p>
                </a>
              </div>
            </div>
            <div className='mt-10 py-16 px-6 border-2 border-gray-50 rounded-4xl'>
              <div className='max-w-md mx-auto'>
                {emailWarning && (
                  <div className="flex mb-1">
                    <WarningIcon />
                    <p className="px-2 text-gray-500 text-sm">{emailWarning}</p>
                  </div>
                )}
                <input
                  className='w-full mb-6 py-5 px-12 text-xl border-2 border-indigo-500 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50'
                  type='email'
                  placeholder={t('signin_email')}
                  onChange={(event) => { setLoginEmail(event.target.value) }}
                />
                <button
                  onClick={handleClick}
                  className='flex py-5 px-10 mx-auto w-full md:max-w-max text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50 rounded-xl'
                >
                  {t('signin_signin')}
                  {signingIn && (
                    <svg className="text-white w-6 h-6 ml-3 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeDasharray="30 30" strokeDashoffset="0" strokeLinecap="round" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

