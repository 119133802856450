import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function HttpCodes(props) {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Navbar user={props.user} logout={props.logout} />
      <section className='relative font-sans bg-white pt-20 lg:pb-20 xl:pb-0 overflow-hidden'>
          <img
            className='absolute bottom-0 md:top-0 right-0 h-96 md:h-full z-10 w-2/4 md:w-1/6 lg:w-auto'
            src='uinel-assets/images/http-codes/frame.png'
            alt='Colorful frame'
          />
          <img
            className='absolute bottom-0'
            src='uinel-assets/images/http-codes/elipse-line.svg'
            alt='Lines in the shape of an elipse'
          />
          <div className='relative container mx-auto px-4 z-10'>
            <div className='flex items-center flex-wrap -mx-4'>
              <div className='w-full md:w-2/3 lg:w-1/2 px-4 mb-20 lg:mb-0'>
                <span
                  className='inline-block py-3 px-7 mb-10 text-xl font-medium font-heading leading-5 text-indigo-500 border border-indigo-500 rounded-6xl'
                  href='#'
                >
                  404
                </span>
                <h2 className='font-medium text-9xl md:text-10xl xl:text-11xl font-heading mb-10 leading-tight max-w-xl'>
                  Sorry, this page does not exist
                </h2>
                {/* <p className='text-base leading-6 text-gray-400 mb-16 xl:mb-40'>
                  or something has gone wrong
                </p> */}
                <Link
                  to='/'
                  className='inline-block py-5 px-10 text-xl leading-6 text-white font-medium tracking-tighter font-heading bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
                >
                  Back home
                </Link>
              </div>
              <div className='w-full lg:w-1/2 px-4'>
                <img
                  src='uinel-assets/images/http-codes/placeholder1.png'
                  alt='Placeholder image of a woman looking surprised'
                />
              </div>
            </div>
          </div>
        </section>
        <Footer />
    </React.Fragment>
  );
}

