import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { consoleLogCustom, getBackendUrl, getCustomerPortalUrl, renderPricesTable } from '../components/Utils';
import { auth } from '../Firebase';
import Faq from '../components/Faq';
import { useTranslation } from 'react-i18next';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Pricing(props) {

  const [isFreeTierButtonIndicator, setIsFreeTierButtonIndicator] = React.useState(false);
  const [isPaidTierButtonIndicator, setIsPaidTierButtonIndicator] = React.useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  async function handleClickGetPaidTier() {
    if (props.user) {
      // If the user is signed in
      if (props.userSubscribed) {
        if (!isPaidTierButtonIndicator) {
          // Manage subscription
          setIsPaidTierButtonIndicator(true);
          launchCustomerPortal();
        } else {
          // Prevent multiple clicks
          return null;
        }
      } else {
        if (!isPaidTierButtonIndicator) {
          // Create a Checkout Session
          setIsPaidTierButtonIndicator(true);
          createCheckoutSession();
        } else {
          // Prevent multiple clicks
          return null;
        }
      }
    } else {
      if (!isPaidTierButtonIndicator) {
        // User is not signed in, redirect to Sign In page
        // TODO: Remember the user subscription for later
        setIsPaidTierButtonIndicator(true);
        navigate('/signin');
      } else {
        // Prevent multiple clicks
        return null;
      }
    }
  };

  function handleClickGetFreeTier(e) {
    e.preventDefault();
    if (props.user) {
      if (props.userSubscribed) {
        if (!isFreeTierButtonIndicator) {
          // Manage subscription
          setIsFreeTierButtonIndicator(true);
          launchCustomerPortal();
        } else {
          // Prevent multiple clicks
          return null;
        }
      } else {
        return null;
      }
    } else {
      if (!isFreeTierButtonIndicator) {
        // User is not signed in, redirect to Sign In page
        setIsFreeTierButtonIndicator(true);
        navigate('/signin');
      } else {
        // Prevent multiple clicks
        return null;
      }
    }
  }

  function getPaidTierButtonText() {
    if (props.user) {
      if (props.userSubscribed) {
        return t('pricing_active');
      } else {
        return t('pricing_get_started');
      }
    } else {
      return t('pricing_get_started');
    }
  }

  function getFreeTierButtonText() {
    if (props.user) {
      if (props.userSubscribed) {
        return t('pricing_downgrade');
      } else {
        return t('pricing_active');
      }
    } else {
      return t('pricing_get_started');
    }
  }

  async function createCheckoutSession() {
    const priceId = 'price_1Nvl1lHtJLu7cd9l6vMRRWQq';
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(getBackendUrl() + "/create-checkout-session", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'priceId': priceId,
        'uid': props.user?.uid,
        'email': props.user?.email,
      },
    });
    const data = await response.json();
    window.location.href = data['redirect-url'];
  }

  async function launchCustomerPortal() {
    // setLaunchingCustomerPortal(true);
    const customerPortalUrl = await getCustomerPortalUrl(props.user.uid, 'https://chattier.dev/pricing'); // Maybe needed for live mode
    window.location.href = customerPortalUrl;
  }

  function renderPricesTooltip(includePrices = true) {
    return (
      <div className="group relative flex justify-center ml-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
        </svg>
        <span class="absolute top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
          {/* Poner título de tabla de precios */}
          <p className='font-bold text-lg text-center'>{t('pricing_table_tooltip_title')}</p>
          <div className='mt-2 text-md font-normal leading-3'>
            {renderPricesTable(t, includePrices)}
          </div>
        </span>
      </div>
    );
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar user={props.user} logout={props.logout} />

        <section className='font-medium bg-white py-10'>
          <div className='container px-4 mx-auto'>
            <h2 className='font-heading flex justify-center text-9xl md:text-10xl xl:text-11xl leading-tight mb-10'>
              {t('pricing_title')}
            </h2>
            <div className='md:grid md:grid-cols-2 border border-gray-100 rounded-5xl overflow-hidden'>
              <div className='py-16 px-6 md:px-10 flex flex-col justify-between md:border-b border-gray-100'>
                <div>
                  <div className='flex justify-start items-center mb-8'>
                    <img
                      className='w-14 h-14'
                      src='uinel-assets/elements/pricing/circle2.svg'
                      alt='Coloured circle icon representing the free plan'
                    />
                    <h3 className='ml-3 font-heading text-6xl md:text-6xl leading-10 tracking-tight'>
                      {t('pricing_free_title')}
                    </h3>
                  </div>
                  <div className='font-heading text-3xl flex items-center mb-14'>
                    <span className='text-base mr-1 -mb-1'>$</span>
                    <span>0</span>
                    <span className='text-base -mb-1 ml-2'>{'/ ' + t('pricing_month')}</span>
                  </div>
                  <ul className='mb-14 xl:mb-36'>
                    <li className='flex items-start leading-6 font-normal text-darkBlueGray-400 mb-3'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>{t('pricing_free_includes_1')}</span>
                      {renderPricesTooltip(false)}
                    </li>
                    <li className='flex items-start leading-6 font-normal text-darkBlueGray-400 mb-3'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>{t('pricing_free_includes_2')}</span>
                    </li>
                    <li className='flex items-start leading-6 font-normal text-darkBlueGray-400 mb-3'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>{t('pricing_free_includes_4')}</span>
                    </li>
                    <li className='flex items-start leading-6 font-normal text-darkBlueGray-400 mb-3'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>{t('pricing_free_includes_3')}</span>
                    </li>
                    {/* <li className='flex items-start leading-6 font-normal text-darkBlueGray-400'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>View conversation history</span>
                    </li> */}
                  </ul>
                </div>
                <button
                  onClick={handleClickGetFreeTier}
                  className='flex py-3 px-10 md:mx-auto w-full md:w-auto text-xl leading-7 text-white font-medium tracking-tighter font-heading text-center bg-purple-500 hover:bg-purple-600 focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 rounded-xl'
                >
                  {getFreeTierButtonText()}
                  {isFreeTierButtonIndicator && (
                    <svg className="text-white w-6 h-6 ml-3 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeDasharray="30 30" strokeDashoffset="0" strokeLinecap="round" />
                    </svg>
                  )}
                </button>
              </div>
              <div className='bg-gray-50 py-16 px-6 md:px-10 flex flex-col justify-between'>
                <div>
                  <div className='flex justify-start items-center mb-8'>
                    <img
                      className='w-14 h-14'
                      src='uinel-assets/elements/pricing/circle1.svg'
                      alt='Coloured circle icon representing the paid plan'
                    />
                    <h3 className='ml-3 font-heading text-6xl md:text-6xl leading-10 tracking-tight'>
                      {t('pricing_paid_title')}
                    </h3>
                  </div>
                  <div className='mb-14 flex items-center justify-between'>
                    <div className='font-heading text-3xl flex items-center'>
                      <span className='text-base mr-1 -mb-1'>$</span>
                      <span>20</span>
                      <span className='text-base -mb-1 ml-2'>{'/ ' + t('pricing_month')}</span>
                    </div>
                  </div>
                  <ul className='mb-14 xl:mb-36'>
                    <li className='flex items-start leading-6 font-normal text-darkBlueGray-400 mb-3'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>{t('pricing_paid_includes_1')}</span>
                    </li>
                    <li className='flex items-start leading-6 font-normal text-darkBlueGray-400 mb-3'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>{t('pricing_paid_includes_2')}</span>
                    </li>
                    <li className='flex items-start leading-6 font-normal text-darkBlueGray-400 mb-3'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>{t('pricing_paid_includes_5')}</span>
                    </li>
                    <li className='flex items-start leading-6 font-normal text-darkBlueGray-400 mb-3'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>{t('pricing_paid_includes_3')}</span>
                    </li>
                    {/* <li className='flex items-start leading-6 font-normal text-darkBlueGray-400 mb-3'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>View conversation history</span>
                    </li> */}
                    <li className='flex items-start leading-6 font-normal text-darkBlueGray-400'>
                      <img
                        className='relative top-1 pt-px mr-6'
                        src='uinel-assets/elements/pricing/checked.svg'
                        alt='Checkmark icon'
                      />
                      <span>{t('pricing_paid_includes_4')}</span>
                      {renderPricesTooltip(true)}
                    </li>
                  </ul>
                </div>
                <button
                  onClick={() => handleClickGetPaidTier('enterprise')}
                  className='flex py-3 px-10 md:mx-auto w-full md:w-auto text-xl leading-7 text-white font-medium tracking-tighter font-heading text-center bg-purple-500 hover:bg-purple-600 focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 rounded-xl'
                >
                  {getPaidTierButtonText()}
                  {isPaidTierButtonIndicator && (
                    <svg className="text-white w-6 h-6 ml-3 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeDasharray="30 30" strokeDashoffset="0" strokeLinecap="round" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    </React.Fragment>
  );
}

