import flag_ar from '../assets/uinel-assets/flags/arab_league.svg';
import flag_da from '../assets/uinel-assets/flags/dk.svg';
import flag_nl from '../assets/uinel-assets/flags/nl.svg';
import flag_en from '../assets/uinel-assets/flags/gb.svg';
import flag_fr from '../assets/uinel-assets/flags/fr.svg';
import flag_ge from '../assets/uinel-assets/flags/de.svg';
import flag_is from '../assets/uinel-assets/flags/is.svg';
import flag_it from '../assets/uinel-assets/flags/it.svg';
import flag_ja from '../assets/uinel-assets/flags/jp.svg';
import flag_pl from '../assets/uinel-assets/flags/pl.svg';
import flag_pt from '../assets/uinel-assets/flags/pt.svg';
import flag_ru from '../assets/uinel-assets/flags/ru.svg';
import flag_es from '../assets/uinel-assets/flags/es.svg';

export const AvailableLanguages = [
  { value: 'ar-AE', name: 'Arabic (Gulf)' },
  { value: 'da-DK', name: 'Danish' },
  { value: 'nl-NL', name: 'Dutch' },
  { value: 'en-AU', name: 'English (Australian)' },
  { value: 'en-GB', name: 'English (British)' },
  { value: 'en-US', name: 'English (US)' },
  { value: 'fr-FR', name: 'French' },
  { value: 'fr-CA', name: 'French (Canadian)' },
  { value: 'de-DE', name: 'German' },
  { value: 'is-IS', name: 'Icelandic' },
  { value: 'it-IT', name: 'Italian' },
  { value: 'ja-JP', name: 'Japanese' },
  { value: 'pl-PL', name: 'Polish' },
  { value: 'pt-BR', name: 'Portuguese (Brazilian)' },
  { value: 'pt-PT', name: 'Portuguese (European)' },
  { value: 'ru-RU', name: 'Russian' },
  { value: 'es-ES', name: 'Spanish (European)' },
  { value: 'es-MX', name: 'Spanish (Mexican)' },
  { value: 'es-US', name: 'Spanish (US)' }
]

export const ReducedAvailableLanguages = [
  { value: 'ar', name: 'Arabic', flag: flag_ar },
  { value: 'da', name: 'Danish', flag: flag_da },
  { value: 'nl', name: 'Dutch', flag: flag_nl },
  { value: 'en', name: 'English', flag: flag_en },
  { value: 'fr', name: 'French', flag: flag_fr },
  { value: 'de', name: 'German', flag: flag_ge },
  { value: 'is', name: 'Icelandic', flag: flag_is },
  { value: 'it', name: 'Italian', flag: flag_it },
  { value: 'ja', name: 'Japanese', flag: flag_ja },
  { value: 'pl', name: 'Polish', flag: flag_pl },
  { value: 'pt', name: 'Portuguese', flag: flag_pt },
  { value: 'ru', name: 'Russian', flag: flag_ru },
  { value: 'es', name: 'Spanish', flag: flag_es }
]

export function getLanguageFromReducedLanguage(reducedLanguage) {
  if (reducedLanguage === 'en') {
    return 'en-US';
  } else if (reducedLanguage === 'es') {
    return 'es-ES';
  } else if (reducedLanguage === 'pt') {
    return 'pt-PT';
  } else if (reducedLanguage === 'fr') {
    return 'fr-FR';
  } else {
    // Single option
    return AvailableLanguages.find(language => language.value.startsWith(reducedLanguage)).value;
  }
}

export const AvailableLanguageModels = [
  { value: 'gpt-4o-mini-2024-07-18', name: 'GPT-4o mini' },
  { value: 'gpt-4o-2024-08-06', name: 'GPT-4o' },
  // { value: 'gpt-3.5-turbo', name: 'GPT-3.5 Turbo' }, // Deprecated
  // { value: 'gpt-4-turbo-preview', name: 'GPT-4 Turbo' }, // Deprecated
  // { value: 'gpt-4', name: 'GPT-4' }, // Deprecated
]