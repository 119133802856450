import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function PrivacyPolicy(props) {

  const { t } = useTranslation();


  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar user={props.user} logout={props.logout} />
        <section className='pt-24 pb-32 bg-gradient-gray2 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <h2 className='mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_1')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_1_paragraph_1')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              {t('privacy_section_1_paragraph_2')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              {t('privacy_section_1_paragraph_3')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_2')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_2_paragraph_1')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_3')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_3_paragraph_1')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              {t('privacy_section_3_paragraph_2')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              {t('privacy_section_3_paragraph_3')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_4')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_4_paragraph_1')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              <ol>
                <li>{'● ' + t('privacy_section_4_paragraph_2')}</li>
                <li>{'● ' + t('privacy_section_4_paragraph_3')}</li>
                <li>{'● ' + t('privacy_section_4_paragraph_4')}</li>
                <li>{'● ' + t('privacy_section_4_paragraph_5')}</li>
                <li>{'● ' + t('privacy_section_4_paragraph_6')}</li>
                <li>{'● ' + t('privacy_section_4_paragraph_7')}</li>
                <li>{'● ' + t('privacy_section_4_paragraph_8')}</li>
              </ol>
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_5')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_5_paragraph_1')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_6')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_6_paragraph_1')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_7')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_7_paragraph_1')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              {t('privacy_section_7_paragraph_2')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              {t('privacy_section_7_paragraph_3')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_8')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_8_paragraph_1')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              {t('privacy_section_8_paragraph_2')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_9')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_9_paragraph_1')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              <ol>
                <li>{'● ' + t('privacy_section_9_paragraph_2')}</li>
                <li>{'● ' + t('privacy_section_9_paragraph_3')}</li>
                <li>{'● ' + t('privacy_section_9_paragraph_4')}</li>
              </ol>
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              {t('privacy_section_9_paragraph_5')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_10')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_10_paragraph_1')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              <ol>
                <li>{'● ' + t('privacy_section_10_paragraph_2')}</li>
                <li>{'● ' + t('privacy_section_10_paragraph_3')}</li>
                <li>{'● ' + t('privacy_section_10_paragraph_4')}</li>
                <li>{'● ' + t('privacy_section_10_paragraph_5')}</li>
                <li>{'● ' + t('privacy_section_10_paragraph_6')}</li>
                <li>{'● ' + t('privacy_section_10_paragraph_7')}</li>
              </ol>
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              {t('privacy_section_10_paragraph_8')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_11')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_11_paragraph_1')}
            </p>
            <p className='pt-3 text-lg text-gray-600 text-left'>
              {t('privacy_section_11_paragraph_2')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_12')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_12_paragraph_1')}
            </p>
            <h2 className='pt-6 mb-4 font-heading font-semibold text-left text-6xl sm:text-7xl text-gray-900'>
              {t('privacy_section_13')}
            </h2>
            <p className='text-lg text-gray-600 text-left'>
              {t('privacy_section_13_paragraph_1')} <a href="mailto:support@chattier.dev" className='text-blue-500 hover:text-blue-700'>support@chattier.dev</a>.
            </p>
          </div>
        </section>
        <Footer />
      </>
    </React.Fragment>
  );
}

