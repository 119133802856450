import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Routes,
  Route,
  useSearchParams,
  BrowserRouter
} from "react-router-dom"
import BubbleChat from '../components/BubbleChat';
import AvatarView from '../components/AvatarView';
import { getDomainFromUrl } from '../components/Domain';
import { query } from 'firebase/firestore';
import { consoleLogCustom, getAvatarSizeInPixels, getAvatarUrl, getChatOrAvatarSize, getChatSize } from '../components/Utils';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function ChatbotIframe(props) {

  const [queryParameters] = useSearchParams();
  const [availableFilters, setAvailableFilters] = useState({}); // Available filters from parent window (only for Shopify stores)
  const [typeChange, setTypeChange] = useState(null); // Type changes from parent window
  const [sizeChange, setSizeChange] = useState(null); // Size changes from parent window
  const [identityChange, setIdentityChange] = useState(null); // Identity changes from parent window
  const [colorChange, setColorChange] = useState(null); // Color changes from parent window
  const [showBubbleChange, setShowBubbleChange] = useState(null); // Minimization changes from parent window
  const [titleChange, setTitleChange] = useState(null); // Title changes from parent window
  const [subtitleChange, setSubtitleChange] = useState(null); // Subtitle changes from parent window
  const [welcomeMessageChange, setWelcomeMessageChange] = useState(null); // Welcome message changes from parent window

  // // Execute code when component is mounted
  // React.useEffect(() => {
  //   consoleLogCustom('ChatbotIframe mounted');
  //   consoleLogCustom('queryParameters: ', queryParameters);
  // }, []);

  // Listen continuously to messages from parent window
  React.useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    function receiveMessage(event) {
      consoleLogCustom('ChatbotIframe received message: ', event.origin);
      // If received message is not from parent window then return
      if (!((event.origin == 'https://chattier-58adc30ed962.herokuapp.com') || (event.origin == 'https://quickstart-1be18c2d.myshopify.com'))) return;
      // If received message is from parent window then execute code
      if (event.data.type === 'type') {
        setTypeChange(event.data.value);
      } else if (event.data.type === 'size') {
        setSizeChange(event.data.value);
      } else if (event.data.type === 'identity') {
        setIdentityChange(event.data.value);
      } else if (event.data.type === 'color') {
        setColorChange(event.data.value);
      } else if (event.data.type === 'bubble') {
        setShowBubbleChange(event.data.value);
      } else if (event.data.type === 'filters') {
        setAvailableFilters(event.data.value);
      } else if (event.data.type === 'title') {
        setTitleChange(event.data.value);
      } else if (event.data.type === 'subtitle') {
        setSubtitleChange(event.data.value);
      } else if (event.data.type === 'welcomeMessage') {
        setWelcomeMessageChange(event.data.value);
      }
    }
    // Return cleanup function to be executed when component is unmounted
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, []);

  React.useEffect(() => {
    if (typeChange === null) return;
    queryParameters.set("type", typeChange);
    window.history.replaceState({}, '', `${window.location.pathname}?${queryParameters.toString()}`);
  }, [typeChange]);

  React.useEffect(() => {
    if (sizeChange === null) return;
    queryParameters.set("size", sizeChange);
    window.history.replaceState({}, '', `${window.location.pathname}?${queryParameters.toString()}`);
  }, [sizeChange]);

  React.useEffect(() => {
    if (identityChange === null) return;
    queryParameters.set("identity", identityChange);
    window.history.replaceState({}, '', `${window.location.pathname}?${queryParameters.toString()}`);
  }, [identityChange]);

  React.useEffect(() => {
    if (colorChange === null) return;
    queryParameters.set("color", colorChange);
    window.history.replaceState({}, '', `${window.location.pathname}?${queryParameters.toString()}`);
  }, [colorChange]);

  React.useEffect(() => {
    if (showBubbleChange === null) return;
    queryParameters.set("bubble", showBubbleChange);
    window.history.replaceState({}, '', `${window.location.pathname}?${queryParameters.toString()}`);
  }, [showBubbleChange]);

  function getChatbotType() {
    var type = null;
    if (typeChange === null) {
      type = queryParameters.get("type");
    } else {
      type = typeChange;
    }
    return type;
  }

  function getChatbotSize() {
    var size = null;
    if (sizeChange === null) {
      size = queryParameters.get("size");
    } else {
      size = sizeChange;
    }
    switch (getChatbotType()) {
      case 'chat':
        return getChatSize(size);
      case 'avatar':
        return getAvatarSizeInPixels(size);
      default:
        return getChatSize(size);
    }
  }

  function getChatbotIdentity() {
    var identity = null;
    if (identityChange === null) {
      identity = queryParameters.get("identity");
    } else {
      identity = identityChange;
    }
    return identity;
  }

  function getChatbotColor() {
    var color = null;
    if (colorChange === null) {
      color = queryParameters.get("color");
    } else {
      color = colorChange;
    }
    return color;
  }

  function getChatbotShowBubble() {
    var showBubble = null;
    if (showBubbleChange === null) {
      showBubble = queryParameters.get("bubble") === 'true';
    } else {
      showBubble = showBubbleChange;
    }
    return showBubble;
  }

  function getChatbotTitle() {
    var title = null;
    if (titleChange === null) {
      title = queryParameters.get("title");
    } else {
      title = titleChange;
    }
    return title;
  }

  function getChatbotSubtitle() {
    var subtitle = null;
    if (subtitleChange === null) {
      subtitle = queryParameters.get("subtitle");
    } else {
      subtitle = subtitleChange;
    }
    return subtitle;
  }

  function getChatbotWelcomeMessage() {
    var welcomeMessage = null;
    if (welcomeMessageChange === null) {
      welcomeMessage = queryParameters.get("welcome");
    } else {
      welcomeMessage = welcomeMessageChange;
    }
    return welcomeMessage;
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <div className={'bg-transparent left-0 top-0' + getChatbotSize()} >
          {getChatbotType() === 'chat' && (
            <BubbleChat
              user={props.user}
              chatbotId={queryParameters.get("cid")}
              size={getChatbotSize()}
              language={queryParameters.get("language")}
              showBubble={getChatbotShowBubble()}
              parent={getDomainFromUrl(document.referrer)} // https://stackoverflow.com/a/69049876. Alternatively: window.location.ancestorOrigins[0] for chromium based browsers
              color={getChatbotColor()}
              dashboardMode={queryParameters.get("dashboardMode") === 'true'}
              shopify={{'filters': availableFilters}}
              title={getChatbotTitle()}
              subtitle={getChatbotSubtitle()}
              welcomeMessage={getChatbotWelcomeMessage()}
            />
          )}
          {getChatbotType() === 'avatar' && (
            <AvatarView
              avatarUrl={getAvatarUrl(getChatbotIdentity())}
              showIFrame={false} user={props.user}
              chatbotId={queryParameters.get("cid")}
              size={getChatbotSize()}
              language={queryParameters.get("language")}
              showBubble={queryParameters.get("bubble") === 'true'}
              parent={getDomainFromUrl(document.referrer)} // https://stackoverflow.com/a/69049876. Alternatively: window.location.ancestorOrigins[0] for chromium based browsers
              aid={queryParameters.get("identity")}
            />
          )}
        </div>
      </>
    </React.Fragment>
  );
}

ChatbotIframe.defaultProps = {
  showBubble: false,
  color: 'indigo',
};

