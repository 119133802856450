import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';
import {
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';
import { auth } from './Firebase.js';
import { doc, getDoc } from "firebase/firestore";
import { db } from './Firebase.js';
import IndexPage from './pages/Index.js';
import PricingPage from './pages/Pricing.js';
import HttpCodesPage from './pages/Http_codes.js';
import SignInPage from './pages/Sign_in.js';
import ProcessSignInPage from './pages/Process_sign_in.js';
import SubscriptionSuccessPage from './pages/Subscription_success.js';
import DashboardPage from './pages/Dashboard.js';
import ContactPage from './pages/Contact.js';
import ProfilePage from './pages/Profile.js';
import UpdateChatbot from './pages/Update_chatbot.js';
import ChatbotIframe from './pages/Chatbot_iframe.js';
import { checkSubscriptionStatusValidity, consoleLogCustom } from './components/Utils.js';
import FaqsPage from './pages/Faqs.js';
import PrivacyPolicyPage from './pages/Privacy_policy.js';

function App() {

  const [user, setUser] = React.useState({});
  const [userData, setUserData] = React.useState({});
  const [userSubscribed, setUserSubscribed] = React.useState(false);

  React.useEffect(() => { // useEffect prevents the app from freezing when setting states
    onAuthStateChanged(auth, (currentUser) => {
      consoleLogCustom('Auth state changed');
      setUser(currentUser);
    });
  }, []);

  // When user logs in, get user data from firestore and check if user is subscribed
  React.useEffect(() => {
    async function getUserData() {
      const userDocRef = doc(db, "users", user?.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        // Read user data
        const data = userDocSnap.data();
        setUserSubscribed(checkSubscriptionStatusValidity(data.subscription_status));
        setUserData(data);
      } else {
        // docSnap.data() is undefined in this case
        consoleLogCustom("No such document!");
      }
    }
    // Only fetch my chatbots if user is logged in
    if (user?.uid) {
      getUserData();
    }
  }, [user]);

  const logout = async () => {
    consoleLogCustom('Signing out...');
    try {
      await signOut(auth);
    } catch (e) {
      consoleLogCustom(e.code);
      consoleLogCustom(e.message);
    };
  };

  return (
    <Routes>
      <Route path="/" element={<IndexPage user={user} logout={logout} />} />
      <Route path="/about" element={<FaqsPage user={user} logout={logout} />} />
      <Route path="/pricing" element={<PricingPage user={user} logout={logout} userSubscribed={userSubscribed} />} />
      <Route path="/signin" element={<SignInPage user={user} />} />
      <Route path="/process-signin" element={<ProcessSignInPage user={user} />} />
      <Route path="/dashboard" element={<DashboardPage user={user} logout={logout} />} />
      <Route path="/new-chatbot" element={<UpdateChatbot user={user} logout={logout} new={true} />} />
      <Route path="/update-chatbot" element={<UpdateChatbot user={user} logout={logout} new={false} />} />
      <Route path="/contact" element={<ContactPage user={user} logout={logout} />} />
      <Route path="/profile" element={<ProfilePage user={user} logout={logout} userData={userData} userSubscribed={userSubscribed} />} />
      <Route path="/privacy" element={<PrivacyPolicyPage user={user} logout={logout} />} />
      <Route path="/subscription-success" element={<SubscriptionSuccessPage user={user} logout={logout} />} />
      <Route path="/chatbot-iframe" element={<ChatbotIframe user={user} />} />
      <Route path="*" element={<HttpCodesPage user={user} logout={logout} />} />
    </Routes>
  );
}

export default App;
