import React from 'react';
import { auth } from '../Firebase';
import { consoleLogCustom, getBackendUrl, getCustomerPortalUrl, getMaxNumberOfChatbots, getSubscriptionStatus, stringifyDate } from './Utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ProfilePanel(props) {

  const [upcomingInvoiceData, setUpcomingInvoiceData] = React.useState({});
  const [launchingCustomerPortal, setLaunchingCustomerPortal] = React.useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Get user next invoice data
  React.useEffect(() => {
    async function getUpcomingInvoiceData() {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(getBackendUrl() + "/get-upcoming-invoice-data", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'uid': props.user?.uid,
        },
      });
      const data = await response.json();
      setUpcomingInvoiceData(data);
    }
    // Only fetch my chatbots if user is logged in
    if (props.userSubscribed) {
      getUpcomingInvoiceData();
      // Show manage subscription button if user is subscribed
      document.getElementsByName('manage-subscription-button')[0].classList.remove('hidden');
    } else {
      // Hide manage subscription button if user is not subscribed
      document.getElementsByName('manage-subscription-button')[0].classList.add('hidden');
    }
  }, [props.userSubscribed]);

  function getEmailFirstLetter() {
    if (props.user?.email) {
      return props.user?.email[0].toUpperCase();
    } else {
      return '';
    }
  }

  function getSubscribedPlanString() {
    switch (props.userData?.subscription_price_id) {
      case 'price_1Nvl1lHtJLu7cd9l6vMRRWQq':
        return t('profile_subscribed_plan_paid');
      default:
        return t('profile_subscribed_plan_free');
    }
  }

  function getSubscriptionStatusString() {
    if (props.userData?.subscription_status === 'active') {
      return t('profile_subscription_active_1') +
        stringifyDate(upcomingInvoiceData?.period_start * 1000, i18n.language) +
        t('profile_subscription_active_2') + stringifyDate(upcomingInvoiceData?.period_end * 1000, i18n.language) + ')';
    } else {
      return t('profile_subscription_inactive');
    }
  }

  function getNumberOfChatbotsString() {
    if (props.userData) {
      if (props.userSubscribed) {
        return props.userData?.chatbots?.length + t('profile_chatbots_message').toLowerCase();
      } else {
        return props.userData?.chatbots?.length + ' chatbots (maximum of ' + getMaxNumberOfChatbots(props.userData?.subscription_price_id) + ')';
      }
    } else {
      return '';
    }
  }

  function getCreditsUsedString() {
    let monthly_credits_cropped = props.userData?.monthly_credits;
    if ((props.userData) && !(props.userSubscribed)) {
      if (props.userData?.monthly_credits > 200) {
        monthly_credits_cropped = 200;
      }
    }
    return monthly_credits_cropped + ' ' + t('profile_credits_used_message_1').toLowerCase() + (props.userSubscribed ? ' (' + t('profile_credits_used_message_2').toLowerCase() + ' $' + (upcomingInvoiceData?.amount_due / 100)?.toFixed(2) + ')' : ' (' + (200 - monthly_credits_cropped) + ' credits remaining)')
  }

  function getFirstSubscribedString() {
    if (props.userData && props.userSubscribed) {
      return stringifyDate(props.userData?.first_subscription_date.seconds * 1000 +
        props.userData?.first_subscription_date.nanoseconds / 1000000, i18n.language);
    } else {
      return '';
    }
  }

  async function handleClickOnManageSubscription() {
    consoleLogCustom('handleClickOnManageSubscription');
    consoleLogCustom('props.user?.email: ' + props.user?.email);
    consoleLogCustom('props.user?.uid: ' + props.user?.uid);
    if (props.user?.email) {
      // User is signed in
      const subscriptionStatus = await getSubscriptionStatus(props.user?.uid);
      if ((subscriptionStatus == 'active') || (subscriptionStatus == 'past_due')) {
        setLaunchingCustomerPortal(true);
        const customerPortalUrl = await getCustomerPortalUrl(props.user?.uid, 'https://chattier.dev/profile'); // Maybe needed for live mode
        window.location.href = customerPortalUrl;
        // setLaunchingCustomerPortal(false);
      }
    } else {
      navigate('/signin');
    }
  }

  return (
    <div className='relative px-8 pt-12 pb-4 mb-2 bg-blueGray-100 rounded-2xl'>
      <div className='flex flex-wrap items-start'>
        <div className='w-full md:w-auto mb-6 md:mb-0'>
          <div className='flex items-center justify-center w-24 h-24 mr-9 bg-white rounded-5xl shadow-sm'>
            <div className='relative max-w-max'>
              <img
                className='w-10 h-10'
                src='uinel-assets/elements/pricing/circle4.svg'
                alt='Coloured circle icon with user initial inside'
              />
              <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-3xl capitalize'>
                {getEmailFirstLetter()}
              </span>
            </div>
          </div>
        </div>
        <div className='w-full md:flex-1'>
          {/* <div className='flex items-start xl:items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                Name
              </h3>
            </div>
            <p className='text-ellipsis text-right text-sm text-darkBlueGray-400 font-heading'>
              {props.userData?.name}
            </p>
          </div> */}
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('profile_email')}
              </h3>
            </div>
            <p className='text-ellipsis text-right text-sm text-darkBlueGray-400 font-heading'>
              {props.userData?.email}
            </p>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('profile_region')}
              </h3>
            </div>
            <p className='text-ellipsis text-right text-sm text-darkBlueGray-400 font-heading'>
              {props.userData?.country}
            </p>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('profile_first_subscribed')}
              </h3>
            </div>
            <p className='text-ellipsis text-sm text-darkBlueGray-400 font-heading'>
              {getFirstSubscribedString()}
            </p>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('profile_subscribed_plan')}
              </h3>
            </div>
            <div className='flex items-center justify-end'>
              <p className='text-ellipsis text-sm text-darkBlueGray-400 font-heading'>
                {getSubscribedPlanString()}
              </p>
              <button
                onClick={handleClickOnManageSubscription}
                name='manage-subscription-button'
                className='hidden ml-3 py-1 px-3 text-xxs leading-5 text-indigo-600 font-bold uppercase bg-indigo-200 rounded-2xl'>
                <div className='flex items-center justify-between'>
                  {launchingCustomerPortal && (
                    <svg className="text-indigo-600 w-4 h-4 mr-2 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeDasharray="30 30" strokeDashoffset="0" strokeLinecap="round" />
                    </svg>
                  )}
                  {t('profile_manage_plan')}
                </div>
              </button>
            </div>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('profile_subscription_status')}
              </h3>
            </div>
            <p className='text-ellipsis text-sm text-darkBlueGray-400 font-heading'>
              {getSubscriptionStatusString()}
            </p>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-4 border-b border-gray-200'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('profile_chatbots')}
              </h3>
            </div>
            <p className='text-ellipsis text-sm text-darkBlueGray-400 font-heading'>
              {getNumberOfChatbotsString()}
            </ p>
          </div>
          <div className='flex flex-wrap items-center justify-between pb-4 mb-6 xl:mb-4'>
            <div className='flex'>
              <svg className="text-indigo-600 w-6 h-6 mr-2 md:mr-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
              </svg>
              <h3 className='text-sm font-medium tracking-tighter'>
                {t('profile_credits_used')}
              </h3>
            </div>
            <p className='text-ellipsis text-sm text-darkBlueGray-400 font-heading'>
              {getCreditsUsedString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePanel;