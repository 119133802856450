import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { useTranslation } from 'react-i18next';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Contact(props) {

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar user={props.user} logout={props.logout} />
        <section className='py-24 2xl:pt-28 2xl:pb-36 bg-blueGray-100 rounded-b-10xl'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/2 px-4 mb-10 lg:mb-0'>
                <div className='max-w-lg lg:mt-20 mx-auto'>
                  <h2 className='text-6xl md:text-7xl xl:text-8xl font-medium font-heading mb-4 lg:mb-6 leading-tight'>
                    {t('contact_title')}
                  </h2>
                  <p className='text-xl mb-4 lg:mb-6'>
                    {t('contact_description')}
                  </p>
                  {/* <div className='max-w-sm py-4 border-b border-gray-200 border-opacity-50'>
                    <div className='flex items-center'>
                      <img
                        className='mr-6 h-14'
                        src='uinel-assets/images/contacts/icon1.svg'
                        alt=''
                      />
                      <a className='text-xl font-semibold' href='#'>
                        + 44 1447881709
                      </a>
                    </div>
                  </div>
                  <div className='max-w-sm py-4 border-b border-gray-200 border-opacity-50'>
                    <div className='flex items-center'>
                      <img
                        className='mr-6 h-14'
                        src='uinel-assets/images/contacts/icon2.svg'
                        alt=''
                      />
                      <a className='text-xl font-semibold' href='#'>
                        uinelchat
                      </a>
                    </div>
                  </div> */}
                  <div className='py-4'>
                    <div className='flex items-center'>
                      <img
                        className='mr-6 h-14'
                        src='uinel-assets/images/contacts/icon3.svg'
                        alt='Icon representing an email address'
                      />
                      <a className='text-xl font-semibold' href='mailto:support@chattier.dev'>
                        support@chattier.dev
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='w-full lg:w-1/2 px-4'>
                <div className='max-w-xl mx-auto py-14 md:pt-16 md:pb-20 px-8 md:px-20 bg-white rounded-xl'>
                  <h3 className='font-heading font-medium text-7xl lg:text-8xl mb-12'>
                    Message
                  </h3>
                  <input
                    className='mb-5 w-full px-12 py-5 text-xl border-2 border-blue-400 rounded-xl placeholder-darkBlueGray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
                    type='email'
                    placeholder='Your Email'
                  />
                  <textarea
                    className='mb-10 w-full px-12 py-5 h-32 text-xl border-2 border-blue-400 rounded-xl placeholder-darkBlueGray-400 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
                    placeholder='Message'
                    name=''
                    id=''
                    cols={30}
                    rows={10}
                    defaultValue={''}
                  />
                  <div className='flex mb-6'>
                    <div className='relative'>
                      <input type='checkbox' />
                      <span className='absolute top-0 left-0 bg-white'>
                        <svg
                          width={28}
                          height={28}
                          viewBox='0 0 28 28'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            x='0.5'
                            y='0.5'
                            width={27}
                            height={27}
                            rx='13.5'
                            stroke='#348BF6'
                          />
                          <path
                            d='M11.135 20.6747L5 14.0559L5.81024 13.1818L11.135 18.9265L22.1898 7L23 7.87414L11.135 20.6747Z'
                            fill='#326BFF'
                          />
                        </svg>
                      </span>
                    </div>
                    <p className='ml-10 text-base text-gray-400'>
                      By singning up, you agree to our Terms, Data Policy and
                      Cookies.
                    </p>
                  </div>
                  <div className='text-right'>
                    <a
                      className='inline-block py-5 px-10 w-full md:w-auto text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
                      href='#'
                    >
                      Send
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <Footer />
      </>
    </React.Fragment>
  );
}

