import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import landingImage from '../assets/uinel-assets/avatars/female1-image.png';
import { Link } from 'react-router-dom';
import YoutubeEmbed from '../components/YoutubeEmbed';
import AvatarView from '../components/AvatarView';
import { getAvatarSizeInPixels, getAvatarUrl } from '../components/Utils';
import { useTranslation } from 'react-i18next';
import { getLanguageFromReducedLanguage } from '../components/AvailableLanguages';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index(props) {

  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <div className='fixed right-2 bottom-2 z-50'>
          <AvatarView
            avatarUrl={getAvatarUrl('female1')}
            showIFrame={false}
            user={props.user}
            chatbotId='zI4jYhNFqimHuzZW2p6S'
            size={getAvatarSizeInPixels('medium')}
            language={getLanguageFromReducedLanguage(i18n.language)}
            showBubble='null'
            parent={'chattier.dev'}
            aid={'female1'}
          />
        </div>
        <Navbar user={props.user} logout={props.logout} />
        <div>
          <section className='overflow-hidden'>
            <div className='bg-indigo-700 rounded-b-9xl'>
              <div className='container px-4 mx-auto'>
                <div className='relative pt-24 z-20 mb-16 xl:mb-0'>
                  <span className='block mb-4 font-medium uppercase text-center tracking-widest text-xs text-white'>
                    {t('home_primary_slogan')}
                  </span>
                  <h1 className='max-w-4xl mx-auto font-heading font-medium text-center text-white text-9xl md:text-10xl leading-tight'>
                    {t('home_secondary_slogan')}
                  </h1>
                </div>
              </div>
              <div className='relative xl:-mt-48 mx-auto max-w-max'>
                <img src={landingImage} alt='Image of a 3D avatar woman smiling' />
              </div>
            </div>
          </section>
        </div>
        <section className='py-24 2xl:pt-40 2xl:pb-52 bg-white'>
          <div className='container px-6 mx-auto'>
            <div className='mb-20 2xl:mb-36 text-center'>
              <span className='block mb-9 text-xs leading-4 font-medium uppercase tracking-widest text-gray-300'>
                {t('home_how_it_works')}
              </span>
              <h2 className='text-9xl md:text-10xl xl:text-11xl leading-none font-heading font-medium'>
                {t('home_quick_setup')}
              </h2>
            </div>
            <div className='flex flex-wrap md:flex-nowrap items-center justify-center -mx-4 mb-20 md:mb-32 2xl:mb-56'>
              <div className='w-full md:w-11/12 px-4 mb-12 md:mb-0'>
                <div className='flex flex-wrap'>
                  <div className='w-full md:w-1/2 xl:w-1/3 px-4'>
                    <div className='relative max-w-sm mx-auto mb-8'>
                      <div className='relative pt-16 pb-20 px-8 md:px-16 bg-white border border-black border-opacity-10 z-10 rounded-3xl'>
                        <div className='relative inline-flex items-center justify-center mb-8 w-12 h-12 leading-6 text-white bg-blue-500 rounded-full'>
                          <span className='text-2xl font-bold'>1</span>
                        </div>
                        <h2 className='mb-14 text-7xl lg:text-8xl leading-tight font-medium font-heading'>
                          {t('home_create_chatbot_title')}
                        </h2>
                        <p className='text-lg text-darkBlueGray-400'>
                          {t('home_create_chatbot_description')}
                        </p>
                      </div>
                      <div className='absolute left-1/2 -bottom-3 transform -translate-x-1/2 w-11/12 h-24 border border-black border-opacity-10 rounded-3xl' />
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 xl:w-1/3 px-4'>
                    <div className='relative max-w-sm mx-auto xl:mt-10 mb-8'>
                      <div className='relative pt-16 pb-20 px-8 md:px-16 bg-white border border-black border-opacity-10 z-10 rounded-3xl'>
                        <div className='relative inline-flex items-center justify-center mb-8 w-12 h-12 leading-6 text-white bg-blue-500 rounded-full'>
                          <span className='text-2xl font-bold'>2</span>
                        </div>
                        <h2 className='mb-14 text-7xl lg:text-8xl leading-tight font-medium font-heading'>
                          {t('home_embed_chatbot_title')}
                        </h2>
                        <p className='text-lg text-darkBlueGray-400'>
                          {t('home_embed_chatbot_description')}
                        </p>
                      </div>
                      <div className='absolute left-1/2 -bottom-3 transform -translate-x-1/2 w-11/12 h-24 border border-black border-opacity-10 rounded-3xl' />
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 xl:w-1/3 px-4'>
                    <div className='relative max-w-sm mx-auto mb-8'>
                      <div className='relative pt-16 pb-20 px-8 md:px-16 bg-white border border-black border-opacity-10 z-10 rounded-3xl'>
                        <div className='relative inline-flex items-center justify-center mb-8 w-12 h-12 leading-6 text-white bg-blue-500 rounded-full'>
                          <span className='text-2xl font-bold'>3</span>
                        </div>
                        <h2 className='mb-14 text-7xl lg:text-8xl leading-tight font-medium font-heading'>
                          {t('home_use_chatbot_title')}
                        </h2>
                        <p className='text-lg text-darkBlueGray-400'>
                          {t('home_use_chatbot_description')}
                        </p>
                      </div>
                      <div className='absolute left-1/2 -bottom-3 transform -translate-x-1/2 w-11/12 h-24 border border-black border-opacity-10 rounded-3xl' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="App">
              <YoutubeEmbed embedId="p981h0Lumns?si=Qz2rsAh-hVDa3n84" />
            </div>
          </div>
        </section>
        <section className='relative py-24 2xl:py-44 font-heading font-medium text-white bg-indigo-700 overflow-hidden rounded-t-10xl'>
          <div className='relative container px-4 mx-auto z-10 text-center'>
            <span className='inline-block mb-10 py-3 px-7 font-medium text-lg leading-5 border border-white rounded-6xl'>
              {t('home_join_us')}
            </span>
            <h2 className='mb-4 xl:mb-6 font-heading text-9xl md:text-10xl xl:text-11xl leading-tight'>
              {t('home_intelligent_chatbots')}
            </h2>
            <h3 className='mb-14 xl:mb-16 font-heading text-7xl md:text-8xl xl:text-9xl leading-tight'>
              {t('home_gpt_based')}
            </h3>
            <p className='mb-2 font-sans font-normal text-3xl leading-6'>
              {t('home_slogan_free_1')}
            </p>
            <p className='mb-14 xl:mb-16 font-sans font-normal text-3xl leading-6'>
              {t('home_slogan_free_2')}
            </p>
            <Link
              to='/pricing'
              className='inline-block py-5 px-10 text-xl leading-6 font-medium tracking-tighter font-heading bg-white hover:bg-gray-50 text-body focus:ring-2 focus:ring-white focus:ring-opacity-50 rounded-xl'
            >
              {t('home_try_button')}
            </Link>
          </div>
          <img
            className='absolute top-0 left-1/2 transform -translate-x-1/2 md:px-6 h-full'
            src='uinel-assets/elements/call-to-action/elipse-bg-blue.svg'
            alt='Background image of a colorful elipse'
          />
        </section>
        <Footer />
      </>
    </React.Fragment>
  );
}

